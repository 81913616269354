/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

.star {
    position: absolute;
    width: 2px;
    height: 2px;
    border-radius: 5px;
}

@keyframes twinkle {
    0 % {
        transform: scale(1, 1);
        background: rgba(255, 255, 255, 0);
        animation-timing-function: linear;
    }
    40% {
        transform: scale(0.8, 0.8);
        background: rgba(255, 255, 255, 1);
        animation-timing-function: ease-out;
    }
    80% {
        background: rgba(255, 255, 255, 0);
        transform: scale(1, 1);
    }
    100% {
        background: rgba(255, 255, 255, 0);
        transform: scale(1, 1);
    }
}
